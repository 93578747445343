@import '~antd/dist/antd.css';

html, body {
    height: 100%;
    background-image: url(../images/bg.gif);
    font-family: "Lucida Grande", Verdana !important;
}

.ant-table, body {
    font-size: 12px;
}

.main-container {
    width: 80%;
    margin-top: 64px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.header {

    border-radius: 7px 7px 0 0;
    display: block;
}

.tool-row {
    background: url(../images/bgr-character-tools.png) repeat-x 100%;
    padding-top: 34px
}

.centered-col {
    text-align: center;
}

.header-logo-row {
    background: url(../images/bgr-header.png) repeat-x;
    height: 54px;
}

.right-col {
    text-align: right;
}

.logo {
    margin-top: -55px;
    max-width: 350px;
    width: 90%;
}

.header-tools {
    padding-left: 20px;
    margin-top: -15px;
}

.header-tools span {
    margin-right: 10px;
}

.top-menu {
    height: 32px;
    background-image: url(../images/bgr-top-menu.png);
}

.top-menu-nav {
    height: 32px;
    overflow-x: auto;
    overflow-y: hidden;
    display: inline-block;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.top-menu-nav ul {
    list-style-type: none;
    margin: 0;
    text-align: center;
    display: inline-flex;
    padding-inline-start: 0px
}

.top-menu-nav ul li {
    list-style-type: disc;
    margin: 0 0 5px;
    padding: 0;
    display: inline-block;
    color: #111 !important;
    padding: 6px 5px;
    white-space: nowrap;
}

.top-menu-nav a:hover {
    text-decoration: underline;
}

.side-menu {
    padding-top: 10px;
    padding-right: 5px;
    padding-bottom: 15px;
    background: url(../images/bgr-main.png);
    border-bottom-right-radius: 15px;
}

.main-content {
    background: url(../images/bgr-main.png);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.box {
    margin: 0px 5px 15px 5px;
    padding: 10px;
    background: #eeeeee;
    border-radius: 7px;
    overflow: auto;
}

.box-icon {
    float: left;
    margin: -10px -5px 0;
}

.box .no-icon {
    padding-left: 10px !important;
}

.box h3 {
    background: url(../images/bgr-item-header.png);
    margin: -10px -10px 15px -10px;
    padding: 5px 5px 5px 42px;
    color: white;
    text-align: left;
    font-weight: bold;
    font-size: 15px;
    border-radius: 7px 7px 0 0;
}

.text-input {
    border-radius: 10px;
    margin-bottom: 5px;
    border: 1px solid #999;
}

.button {
    border-radius: 10px;
    margin-bottom: 5px;
    border: 1px solid #999;
}

.side-menu-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ant-message-notice-content {
    border-radius: 10px;
}

.screen {
    background: url(../images/bgr-main.png) repeat;
    padding-top: 10px;
    padding-left: 5px;
    padding-bottom: 15px;
    border-bottom-left-radius: 15px;
}

.link, a {
    color: #006699;
    cursor: pointer;
}

.latest-news {}

.news-entry h4, .news-entry span {
    display: inline-block;
}

.news-entry span {
    font-style: italic;
    font-size: 12px;
}

.latest-members {}

.home-member {
    text-align: center;
    display: grid;
}

.home-member a {
    text-align: center;
}

.staff-member {
    text-align: left;
    width: fit-content;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 15px;
}

.staff-member .staff-name {
    text-align: center;
}

.staff-member .lang-name {
    text-align: center;
    font-style: italic;
}

.rank-text {
    font-style: italic;
    margin-left: 10px;
    margin-bottom: 5px;
}

.avatar {
    height: 156px !important;
    width: 114px !important;
    position: relative;
    text-align: center;
    margin: 0 auto;
    border-radius: 7px;
    z-index: 0;
}

.feedback-text {
    margin-bottom: 10px;
}

div.tb {
    clear: both;
    margin: 0 0 5px;
    overflow: auto;
    padding: 0;
}

div.tbc {
    border-radius: 7px !important;
    padding: 8px 6px 8px 6px;
    background: #fff;
}

div.tbfblr {
    margin-right: 20px;
    text-align: right;
}

img.servertime {
    margin-right: 2px;
    margin-left: 5px;
}

.admin-feedback {
    background: #FFFFB1 none repeat scroll 0% 0% !important;
}

.own-feedback {
    background: #d9d9d9 none repeat scroll 0% 0% !important;
}

.feedback-area {
    border-radius: 7px !important;
    background: #fff;
    border: 1px solid #999;
    height: 100px;
    margin-bottom: 5px;
    padding: 3px;
    width: 99%;
    font-family: "Lucida Grande", Verdana, Sans-Serif;
    font-size: 11px;
    color: #000;
}

.black {
    color: black !important;
}

.italic-info {
    font-style: italic;
}

.p-select {
    width: 100%;
    border-radius: 10px;
}

.p-select .ant-select-selector {
    border-radius: 10px !important;
}

.recipe-card {
    width: 95%;
    border-radius: 10%;
    background: #cbddf3;
}

.recipe-card .ant-card-head {
    padding: 0 16px;
}

.recipe-card .ant-card-body {
    padding: 16px;
}

.badboys-list {
    gap: 1px !important;
}

.badboy-entry {}

.namelist span {
    color: #006699;
}

.namelist-lang {
    font-style: italic;
    color: #000 !important;
}

.tool-stats {
    width: 100%;
    text-align: center;
    margin-top: 15px;
}

.crop-container {
    width: 100%;
}

.cropped-image {
    border-radius: 10px
}

.start-again {
    font-size: 16px;
    color: #007bbd;
    cursor: pointer;
}

.start-again span {
    font-size: 25px;
}

.avator-textarea {
    border-radius: 15px;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.p-datepicker {
    border-radius: 10px;
    margin-bottom: 5px;
    border: 1px solid #999;
}

.tour-days {
    display: inline-grid;
}

.available-cities {
    width: 100%;
    border-right: 1px solid #a3a3a3;
}

.picked-cities {
    width: 100%;
    padding-left: 10px;
}

.calculated-shows {
    gap: 0 !important;
    text-align: left;
    width: 100%;
}

.footer div {
    text-align: center;
    border-top: 3px solid white;
}

.health-table {
    overflow-x: auto;
}

.health-table .ant-table-thead>tr>th {
    background: #cfcfcf !important;
}

.health-table .ant-table-tbody {
    background: white;
}

.label-input-holder {
    width: 100%;
    display: inline-flex;
}

.label-input-holder span {
    margin-right: 5px;
    white-space: pre;
}

.label-input-holder textarea {
    min-height: 120px;
}

.script-verified {
    font-size: 14px;
    font-style: italic;
}

.script-screenshot {
    border-radius: 9px;
}

.no-gap {
    gap: 0 !important;
}