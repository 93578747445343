/* ANT OVERRIDE */

.ant-modal-title, .ant-modal-header {
    background-color: #d1d1d1;
}

.ant-modal-content {
    background: transparent;
    box-shadow: none;
}

.ant-modal-header {
    border-bottom: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.ant-modal-body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #d1d1d1;
}

.ant-modal-content {
    border-radius: 15px;
}

.ant-modal-footer {
    border-top: none;
    text-align: center;
}

.ant-modal-footer span {
    cursor: pointer;
    color: white;
}


/* ANT OVERRIDE */